/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 src/assets/models/RiserDrillingSystem.glb -o src/components/Riser.jsx 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Riser(props) {
  const { nodes, materials } = useGLTF('../assets/models/RiserDrillingSystem.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.418, 0, 0.023]} rotation={[0, -1.571, 0]}>
        <mesh geometry={nodes.Circle058.geometry} material={materials.Drillship} />
        <mesh geometry={nodes.Circle058_1.geometry} material={materials.Helipad} />
        <mesh geometry={nodes.Circle058_2.geometry} material={materials['Brand board']} />
      </group>
      <group position={[0.026, -2.893, 0.016]}>
        <mesh geometry={nodes.Circle003.geometry} material={materials['PetSim Palette Matte']} />
        <mesh geometry={nodes.Circle003_1.geometry} material={materials['PetSim Palette glossy']} />
      </group>
      <group position={[0.02, -7.431, 0.012]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh geometry={nodes.Circle006.geometry} material={materials.Cement} />
        <mesh geometry={nodes.Circle006_1.geometry} material={materials['PetSim Palette glossy']} />
      </group>
      <mesh geometry={nodes.Thruster.geometry} material={materials.Drillship} position={[5.611, 0.007, 0.023]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.Thruster004.geometry} material={materials.Drillship} position={[-4.846, 0.01, -0.426]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.Thruster005.geometry} material={materials.Drillship} position={[-4.846, 0.007, 0.491]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.Thruster003.geometry} material={materials.Drillship} position={[-3.024, 0.007, 0.023]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.Thruster002.geometry} material={materials.Drillship} position={[3.365, 0.01, -0.426]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.Thruster001.geometry} material={materials.Drillship} position={[3.365, 0.007, 0.491]} rotation={[0, -1.571, 0]} />
      <mesh geometry={nodes.Hydrophones.geometry} material={materials['PetSim Palette Matte']} position={[0.021, 0, 0.023]} />
      <mesh geometry={nodes.Transponders.geometry} material={materials['PetSim Palette Matte']} position={[0.022, -6.158, -0.019]} />
      <group position={[13.066, 17.454, 0]} scale={2.117}>
        <mesh geometry={nodes.Circle014.geometry} material={materials['PetSim Palette glossy']} />
        <mesh geometry={nodes.Circle014_1.geometry} material={materials['Solar panels']} />
      </group>
      <group position={[7.417, 17.454, -3.303]} scale={2.117}>
        <mesh geometry={nodes.Circle014.geometry} material={materials['PetSim Palette glossy']} />
        <mesh geometry={nodes.Circle014_1.geometry} material={materials['Solar panels']} />
      </group>
      <group position={[0.627, 17.454, 2.377]} scale={2.117}>
        <mesh geometry={nodes.Circle014.geometry} material={materials['PetSim Palette glossy']} />
        <mesh geometry={nodes.Circle014_1.geometry} material={materials['Solar panels']} />
      </group>
      <mesh geometry={nodes.Drawworks.geometry} material={nodes.Drawworks.material} position={[-0.778, 2.469, 0.022]} />
      <mesh geometry={nodes.Mud_processing.geometry} material={materials['PetSim Palette Matte']} position={[-3.165, 1.567, 0]} rotation={[0, Math.PI / 2, 0]} />
      <mesh geometry={nodes.ROV.geometry} material={materials['PetSim Palette Matte']} position={[1.89, -6.487, 0.651]} rotation={[Math.PI, -1.378, Math.PI]} />
      <group position={[0.025, 4.582, 0.014]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.Circle001.geometry} material={materials['PetSim Palette Matte']} />
        <mesh geometry={nodes.Circle001_1.geometry} material={materials['PetSim Palette glossy']} />
      </group>
      <group rotation={[0, Math.PI / 2, 0]}>
        <mesh geometry={nodes.Cube007.geometry} material={materials.Formation} />
        <mesh geometry={nodes.Cube007_1.geometry} material={materials.Sand} />
      </group>
      <mesh geometry={nodes.Mud_flow_line.geometry} material={materials['PetSim Palette Matte']} position={[0.025, 4.582, 0.014]} rotation={[0, -Math.PI / 2, 0]} />
      <group position={[0.028, 0.72, 0.023]}>
        <mesh geometry={nodes.Circle012.geometry} material={materials['PetSim Palette Matte']} />
        <mesh geometry={nodes.Circle012_1.geometry} material={materials['PetSim Palette glossy']} />
      </group>
      <mesh geometry={nodes.ROV_tether.geometry} material={materials['PetSim Palette Matte']} position={[3.675, 1.714, 1.605]} />
      <mesh geometry={nodes.ROV_Supply_cage.geometry} material={materials['PetSim Palette glossy']} position={[3.675, -4.539, 1.605]} />
      <mesh geometry={nodes.BezierCurve001.geometry} material={nodes.BezierCurve001.material} position={[-0.108, 7.267, 0.059]} />
      <mesh geometry={nodes.BezierCurve002.geometry} material={nodes.BezierCurve002.material} position={[0.132, 7.267, 0.059]} rotation={[Math.PI, 0, Math.PI]} />
      <mesh geometry={nodes.Connector.geometry} material={nodes.Connector.material} position={[0.02, -4.933, 0.015]} rotation={[0, -Math.PI / 2, 0]} />
      <mesh geometry={nodes.Drillstring.geometry} material={materials['PetSim Palette Matte']} position={[0.026, 2.68, 0.016]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.Crossover_Sub.geometry} material={materials['PetSim Palette Matte']} position={[0, -15.884, 0]}>
          <mesh geometry={nodes['Collar_(Stabilizer)'].geometry} material={materials['PetSim Palette Matte']} position={[0, -0.136, 0]}>
            <mesh geometry={nodes.Bit_Sub.geometry} material={materials['PetSim Palette Matte']} position={[0, -0.173, 0]}>
              <group position={[0, -0.252, 0]}>
                <mesh geometry={nodes.Circle022.geometry} material={materials['PetSim Palette Matte']} />
                <mesh geometry={nodes.Circle022_1.geometry} material={materials.Thread} />
              </group>
            </mesh>
          </mesh>
        </mesh>
      </mesh>
      <mesh geometry={nodes.Choke_Line.geometry} material={materials['PetSim Palette Matte']} position={[0.026, -2.893, 0.016]} rotation={[0, -0.766, 0]} />
      <mesh geometry={nodes.BOP_Cage001.geometry} material={materials['PetSim Palette Matte']} position={[0.022, -6.158, -0.019]} />
    </group>
  )
}

useGLTF.preload('../assets/models/RiserDrillingSystem.glb')
