import React, { Suspense, useEffect, useRef } from 'react'
import { Cloud, Clouds, Environment, OrbitControls, PerspectiveCamera, Stars, Stats, Text } from '@react-three/drei'
import { MeshBasicMaterial } from 'three';
import { useFrame } from '@react-three/fiber';
import Riser from './Riser';

import gsap from "gsap"
import { useGSAP } from '@gsap/react'
import Ocean from './Ocean';

function Scene() {
    // const meshRef = useRef();

    // const { contextSafe } = useGSAP({ scope: meshRef })

    // useEffect(() => {
    //     moveCube()
    // }, [meshRef])


    // const moveCube = contextSafe((pos = 4) => {
    //     gsap.to(meshRef.current.position, {
    //       duration: 6, z: pos, ease: "elastic.inOut", onComplete: function () {
    //         moveCube(pos === 4 ? -4 : 4);
    //       }
    //     });
    //   })

    // useFrame(() => {
    //   meshRef.current.rotation.y += 0.005;
    // });

  return (
    <>
      <Stats/>
      <PerspectiveCamera makeDefault fov={75} position={[0, 0.3,6]}>
      </PerspectiveCamera>
      <directionalLight position={[0, 1, 2]} intensity={0.5} />
      <ambientLight intensity={0.1} />
      <Riser/>
      <Suspense fallback={null}> 
        <Ocean/>
      </Suspense>
      {/* <fog attach="fog" color="hotpink" near={1} far={10} /> */}
      {/* <fog attach="fog" args={['red', .01, 10]}  /> */}
      {/* <Clouds material={MeshBasicMaterial}>
          <Cloud seed={1} scale={2} volume={5} color="grey" fade={100} />
      </Clouds> */}
      {/* <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade speed={1} /> */}
      {/* <Environment preset='sunset'/> */}
      <OrbitControls
      enablePanel={true}
      />
    </>
  )
}

export default Scene