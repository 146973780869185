import "./App.css";
import { Canvas } from "@react-three/fiber";
import Scene from "./components/scene";
import { ARButton, XR } from "@react-three/xr";

function App() {
  return (
    <>
      <ARButton />
      <Canvas
        // style={{
        //   background:
        //     "radial-gradient(circle, rgba(185,185,185,1) 0%, rgba(114,114,114,1) 100%)",
        // }}
      >
        <XR>
          <Scene />
        </XR>
      </Canvas>
    </>
  );
}

export default App;
